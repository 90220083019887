import { React, useState } from 'react';
import 'survey-core/defaultV2.min.css';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { themeJson } from "./data/themeJson";
import { json } from "./data/json";
import Loading from './components/Loading';

const BACKEND_URL = process.env.NODE_ENV === 'development' 
  ? process.env.REACT_APP_BACKEND_URL_DEV 
  : process.env.REACT_APP_BACKEND_URL_PROD;

// const FRONTEND_URL = process.env.NODE_ENV === 'development' 
//   ? process.env.REACT_APP_FRONTEND_URL_DEV 
//   : process.env.REACT_APP_FRONTEND_URL_PROD;

function App() {
  // const [pdfUrl, setPdfUrl] = useState(false); // State to store PDF URL
  const [pdfUrl, setPdfUrl] = useState(false); // State to store PDF URL
  const [isLoading, setIsLoading] = useState(false); // State to handle loading state
  const survey = new Model(json);
  survey.applyTheme(themeJson);
  
  // const handleDownload = () => {
  //   // Check if PDF URL is available
  //   if (pdfUrl) {
  //     // Convert the PDF content into a Blob
  //     const blob = new Blob([pdfUrl], { type: 'application/pdf' });

  //     // Create a temporary URL for the Blob
  //     const url = window.URL.createObjectURL(blob);

  //     // Create a link element and click it programmatically
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', 'renovation_bathroom.pdf');
  //     document.body.appendChild(link);
  //     link.click();

  //     // Clean up resources
  //     URL.revokeObjectURL(url);
  //   }
  // };
  const downloadDefaultPDF = () => {
    const mypdf = 'https://www.renovmarket.gr/bathroom_renovation.pdf';  
    fetch(mypdf)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'bathroom_renovation.pdf');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(error => {
        console.error('Error fetching PDF:', error);
      });
  }
  

  const submitResults = async (sender) => {
    try {
      setIsLoading(true); // Set loading state to true
      const response = await submitUserAnswers(
        BACKEND_URL,
        sender.data
      );
      // Assuming the response contains the PDF content
      setPdfUrl(response); // Set PDF URL
    } catch (error) {
      console.error('Error fetching PDF:', error);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  const submitUserAnswers = async (url, data) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error('Failed to send survey results');
    }
    return await response.json(); // just the final price
    // return await response.blob(); // Return PDF content as Blob
  };

  survey.onComplete.add(submitResults); // Attach submitResults function to onComplete event of survey

  return (
    <div>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'teal' }}>
          <Loading />
        </div>
      ) : pdfUrl ? (
          // If PDF URL is available, render a button to download the PDF
          // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'teal' }}>
          //   <Button variant="contained" onClick={handleDownload} color="primary" size='large'>Η προσφορα μου</Button>
          // </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100vh', backgroundColor: 'teal' }}>
            <h2 style={{ color: 'white', fontFamily: 'arial' }}>Το συνολικό κόστος ανακαίνισης του μπάνιου βάσει των επιλογών σας ανέρχεται σε {pdfUrl} συμπεριλαμβανομένου Φ.Π.Α</h2>
            <Button color="primary" size='large' variant="contained" onClick={downloadDefaultPDF} endIcon={<FileDownloadIcon />}>Προσφορα PDF</Button>
          </div>
        ) : (
        // Otherwise, render the survey component
        <Survey model={survey} />
      )}
    </div>
  );
}

export default App;
